import React, { Component } from "react";
import FlipCountDown from "@rumess/react-flip-countdown";

class Countdown extends Component {
  render() {
    const { renderCountdown } = this;
    return renderCountdown();
  }

  renderCountdown = () => {
    const { getStyle } = this;
    return (
      <div style={getStyle()}>
        <FlipCountDown hideYear endAt={"2024-08-08"} />
      </div>
    );
  };

  getStyle = () => {
    const { isLandscape } = this.props;
    const { getLandscapeStyle, getPortraitStyle } = this;
    return isLandscape ? getLandscapeStyle() : getPortraitStyle();
  };

  getLandscapeStyle = () => {
    let zoom;
    const { deviceType } = this.props;
    switch (deviceType) {
      case "isMobile":
        zoom = "0.3";
        break;
      case "isTablet":
        zoom = "0.4";
        break;
      case "isLaptop":
        zoom = "0.6";
        break;
      case "isDesktop":
        zoom = ".8";
        break;
      case "isXLDesktop":
        zoom = ".95";
        break;
      default:
        zoom = "0.5";
    }
    return {
      zoom: zoom,
    };
  };

  getPortraitStyle = () => {
    let zoom;
    const { deviceType, width } = this.props;
    switch (deviceType) {
      case "isMobile":
        if (width > 400) {
          zoom = 400 / width;
          if (zoom > 0.5) zoom = 0.5;
        } else {
          zoom = (width / 400) * 0.5;
        }
        break;
      case "isTablet":
        zoom = 400 / width;
        if (zoom < 0.75) zoom = 0.75;
        break;
      case "isLaptop":
        zoom = "1.25";
        break;
      case "isDesktop":
        zoom = "1.75";
        break;
      case "isXLDesktop":
        zoom = "2.5";
        break;
      default:
        zoom = "0.5";
    }
    return {
      zoom: zoom,
    };
  };
}

export default Countdown;
