import React, { Component } from "react";
import "../assets/css/Slogan.css";

class Slogan extends Component {
  render() {
    const { renderSlogan } = this;
    return renderSlogan();
  }

  renderSlogan = () => {
    const { isLandscape } = this.props;
    return (
      <div className={isLandscape ? "slogan" : "slogan slogan-portrait"}>
        A <span className="slogan-refreshing">Refreshing </span>
        Way <br />
        To Platform
      </div>
    );
  };
}

export default Slogan;
