import React from "react";
import { Landscape, Portrait } from "./exports/Pages";
import { CopyrightFooter } from "./exports/Components";
import {
  useDeviceType,
  useLandscape,
  useWindowDimensions,
} from "./services/useWindowDimensions.js";
import "./assets/css/App.css";

const App = () => {
  const commonProps = {
    deviceType: useDeviceType(),
    width: useWindowDimensions().width,
    isLandscape: useLandscape(),
  };
  return (
    <div className="app-container">
      <div className="inner-app-container">
        {commonProps.isLandscape ? (
          <Landscape {...commonProps} />
        ) : (
          <Portrait {...commonProps} />
        )}
      </div>
      <CopyrightFooter />
    </div>
  );
};

export default App;
