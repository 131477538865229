import React, { Component } from "react";
import { Grid } from "@mui/material";
import { PortraitBody, PortraitHeader } from "../exports/Parts";
import "../assets/css/Portrait.css";

class Portrait extends Component {
  render() {
    const { renderHeader, renderBody } = this;
    return (
      <Grid className="portrait-container" container>
        {renderHeader()}
        {renderBody()}
      </Grid>
    );
  }

  renderHeader = () => {
    const { props } = this;
    return <PortraitHeader {...props} />;
  };

  renderBody = () => {
    const { props } = this;
    return <PortraitBody {...props} />;
  };
}

export default Portrait;
