import React, { Component } from "react";
import { Grid } from "@mui/material";
import {
  BuildImage,
  Countdown,
  Description,
  Slogan,
} from "../exports/Components";
import "../assets/css/PortraitBody.css";

class PortraitBody extends Component {
  render() {
    const { renderPortraitBody } = this;
    return renderPortraitBody();
  }

  renderPortraitBody = () => {
    return (
      <Grid className="portrait-body" container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Slogan {...this.props} />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <BuildImage {...this.props} />
        </Grid>
        <Grid item xs={12}>
          <Description {...this.props} />
        </Grid>
        <Grid item xs={12}>
          <Countdown {...this.props} />
        </Grid>
      </Grid>
    );
  };
}

export default PortraitBody;
