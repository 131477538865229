import React, { Component } from "react";
import "../assets/css/Description.css";

class Description extends Component {
  render() {
    const { renderDescription } = this;
    return renderDescription();
  }

  renderDescription = () => {
    const { isLandscape } = this.props;
    return (
      <div
        className={
          isLandscape
            ? "slogan-description"
            : "slogan-description slogan-description-portrait"
        }
      >
        Revolutionizing the way markets streamline their services, globally.
        Launching soon.
      </div>
    );
  };
}

export default Description;
