import React, { Component } from "react";
import "../assets/css/CareersButton.css";

class CareersButton extends Component {
  render() {
    const { renderCareersButton } = this;
    return renderCareersButton();
  }

  renderCareersButton = () => {
    return (
      <a
        className="careers-button"
        href="mailto:careers@developmint.co?subject=Careers%20at%20Developmint"
      >
        Careers
      </a>
    );
  };
}

export default CareersButton;
