import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function useDeviceType() {
  const isMobile = useMediaQuery(useTheme().breakpoints.only("xs"));
  const isTablet = useMediaQuery(useTheme().breakpoints.only("sm"));
  const isLaptop = useMediaQuery(useTheme().breakpoints.only("md"));
  const isDesktop = useMediaQuery(useTheme().breakpoints.only("lg"));
  const isXLDesktop = useMediaQuery(useTheme().breakpoints.only("xl"));
  if (isMobile) return "isMobile";
  if (isTablet) return "isTablet";
  if (isLaptop) return "isLaptop";
  if (isDesktop) return "isDesktop";
  if (isXLDesktop) return "isXLDesktop";
  return "";
}

function useLandscape() {
  const windowDimensions = useWindowDimensions();
  return windowDimensions.width > windowDimensions.height;
}

export { useDeviceType, useLandscape, useWindowDimensions };
