import React, { Component } from "react";
import { Grid } from "@mui/material";
import {
  BuildImage,
  Countdown,
  Description,
  Slogan,
} from "../exports/Components";
import "../assets/css/LandscapeBody.css";

class LandscapeBody extends Component {
  render() {
    const { renderLandscapeBody } = this;
    return renderLandscapeBody();
  }

  renderLandscapeBody = () => {
    const { renderLeft, renderRight } = this;
    return (
      <Grid className="landscape-body" container alignItems="center">
        {renderLeft()}
        {renderRight()}
      </Grid>
    );
  };

  renderLeft = () => {
    const { props } = this;
    return (
      <Grid
        className="landscape-body-left"
        item
        xs={6}
        container
        direction="column"
        alignItems="center"
      >
        <Grid item xs={10} container alignItems="center">
          <div>
            <Slogan {...props} />
            <Description {...props} />
          </div>
        </Grid>
        <Grid item container xs={2}>
          <Countdown {...props} />
        </Grid>
      </Grid>
    );
  };

  renderRight = () => {
    const { props } = this;
    return (
      <Grid item xs={6} container justifyContent="center">
        <BuildImage {...props} />
      </Grid>
    );
  };
}

export default LandscapeBody;
