import React, { Component } from "react";
import { Grid } from "@mui/material";
import { CareersButton, Logo } from "../exports/Components";
import "../assets/css/LandscapeHeader.css";

class LandscapeHeader extends Component {
  render() {
    const { renderLandscapeHeader } = this;
    return renderLandscapeHeader();
  }

  renderLandscapeHeader = () => {
    const { renderLogo, renderCareersButton } = this;
    return (
      <Grid container justifyContent="space-between">
        {renderLogo()}
        {renderCareersButton()}
      </Grid>
    );
  };

  renderLogo = () => {
    return (
      <Grid className="landscape-header-logo" item xs={5}>
        <Logo />
      </Grid>
    );
  };

  renderCareersButton = () => {
    return (
      <Grid
        className="landscape-header-button"
        container
        item
        xs={2}
        justifyContent="flex-end"
      >
        <CareersButton {...this.props} />
      </Grid>
    );
  };
}

export default LandscapeHeader;
