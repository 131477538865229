import React, { Component } from "react";
import { Grid } from "@mui/material";
import { LandscapeBody, LandscapeHeader } from "../exports/Parts";
import "../assets/css/Landscape.css";

class Landscape extends Component {
  render() {
    const { renderHeader, renderBody } = this;
    return (
      <Grid className="landscape-container" container>
        {renderHeader()}
        {renderBody()}
      </Grid>
    );
  }

  renderHeader = () => {
    const { props } = this;
    return <LandscapeHeader {...props} />;
  };

  renderBody = () => {
    const { props } = this;
    return <LandscapeBody {...props} />;
  };
}

export default Landscape;
