import React, { Component } from "react";
import { LogoImage } from "../exports/Assets";
import "../assets/css/Logo.css";

class Logo extends Component {
  render() {
    const { renderLogo } = this;
    return renderLogo();
  }

  renderLogo = () => {
    return (
      <React.Fragment>
        <img src={LogoImage} className="logo-mint" alt="logo" />
        Developmint
      </React.Fragment>
    );
  };
}

export default Logo;
