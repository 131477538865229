import React, { Component } from "react";
import { BuildImage } from "../exports/Assets";
import "../assets/css/BuildImage.css";

class Build extends Component {
  render = () => {
    const { renderBuild } = this;
    return renderBuild();
  };

  renderBuild = () => {
    return <img className="logo-build" src={BuildImage} alt="Developmint" />;
  };
}

export default Build;
