import React, { Component } from "react";
import "../assets/css/CopyrightFooter.css";

class CopyrightFooter extends Component {
  render() {
    const { renderCopyrightFooter } = this;
    return renderCopyrightFooter();
  }

  renderCopyrightFooter = () => {
    return (
      <div className="copyright-text">
        Copyright © 2023 Developmint LLC — San Francisco, CA
      </div>
    );
  };
}

export default CopyrightFooter;
